import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, setLogout } from 'features/auth/authSlice';
import { GameSessionApi } from 'api/gameSessionApi';
import React from 'react';
import { useState } from 'react';

export const GameSessionCard = (props) => {
    const navigate = useNavigate();

    const navigateToGameSession = () => {
        navigate(`/gameSession/${props.gameSession._id}`);
    };

    const currentUserToken = useSelector(selectToken);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleDeleteGameSession = () => {
        setIsLoading(true);
        console.log('deleting game session', props.gameSession._id);
        GameSessionApi.deleteGameSession(
            currentUserToken,
            props.gameSession._id
        )
            .then(() => {
                props.gameDeleted();
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    dispatch(setLogout());
                }
            })
            .finally(() => {
                setIsLoading(false);
                closeDeletionConfirmationModal();
                props.gameDeleted();
            });
    };

    const handleClickDeleteGameSession = () => {
        handleDeleteGameSession();
    };

    const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
        useState(false);

    const handleShowDeletionConfirmationModal = () => {
        setShowDeletionConfirmationModal(true);
    };

    const closeDeletionConfirmationModal = () => {
        setShowDeletionConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Card sx={{ minWidth: 250 }}>
                <CardActionArea onClick={navigateToGameSession}>
                    <CardMedia
                        component="img"
                        height="140"
                        image="/assets/gameLogos/palworld.jpg"
                        alt={props.gameSession.gameName}
                    />
                    <CardContent>
                        <Typography variant="h5" color="primary" gutterBottom>
                            {props.gameSession.name}
                        </Typography>
                        <Typography variant="body1">
                            {`Passes: ${props.gameSession.passes}`}
                        </Typography>
                        <Typography variant="body1">
                            {`Smashes: ${props.gameSession.smashes}`}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button
                        size="small"
                        color="error"
                        onClick={handleShowDeletionConfirmationModal}
                        size="small"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </CardActions>
            </Card>
            <Dialog
                open={showDeletionConfirmationModal}
                onClose={closeDeletionConfirmationModal}
            >
                <DialogTitle>Delete Game Session?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this game session? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeletionConfirmationModal}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClickDeleteGameSession}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
};
