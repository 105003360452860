import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    gameSession: null
};

export const gameSessionSlice = createSlice({
    name: 'gameSession',
    initialState,
    reducers: {
        setGameSession: (state, action) => {
            state.gameSession = action.payload;
        }
    }
});

export const { setGameSession } = gameSessionSlice.actions;
export default gameSessionSlice.reducer;
