import {
    Backdrop,
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';
import Navbar from 'scenes/navbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GameSessionApi } from 'api/gameSessionApi';
import { selectToken, setLogout } from 'features/auth/authSlice';
import { GameSessionCard } from 'scenes/widgets/GameSessionCard';

const HomePage = () => {
    const currentUserToken = useSelector(selectToken);
    const navigate = useNavigate();
    const [createdGameSessions, setCreatedGameSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleGetGameSessions = async () => {
        setIsLoading(true);
        GameSessionApi.getGameSessions(currentUserToken)
            .then((response) => {
                setCreatedGameSessions(response);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    dispatch(setLogout());
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleGetGameSessions();
    }, []);

    const handleClickCreateGameSession = async () => {
        navigate('/createGameSession');
    };

    const gameDeleted = () => {
        handleGetGameSessions();
        console.log('game deleted');
    };

    return (
        <Box>
            <Navbar />
            <Box width="100%" padding="2rem 6%" gap="0.5rem" textAlign="center">
                <Typography fontWeight="bold" fontSize="32px" color="primary">
                    Game Sessions
                </Typography>
                <Divider sx={{ mb: 4 }} />
                {!isLoading && (
                    <React.Fragment>
                        {createdGameSessions &&
                        createdGameSessions.length > 0 ? (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    {createdGameSessions.map((gameSession) => (
                                        <Grid
                                            key={gameSession._id}
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                        >
                                            <GameSessionCard
                                                gameSession={gameSession}
                                                gameDeleted={gameDeleted}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Divider sx={{ mt: 4, mb: 4 }} />
                            </React.Fragment>
                        ) : (
                            <Typography
                                variant="h5"
                                color="primary"
                                gutterBottom
                                sx={{ mb: 4 }}
                            >
                                You have no game sessions yet!
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            onClick={handleClickCreateGameSession}
                        >
                            Create Game Session
                        </Button>
                    </React.Fragment>
                )}
                <Backdrop open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </Box>
    );
};

export default HomePage;
