import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://shouldismash-backend-bfed0d556d5b.herokuapp.com/',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

const errorHandler = (error) => {
    const status = error.response?.status;
    if (status === 401) {
        console.log('Unauthorized');
    } else {
        console.log('Error', error);
    }
    return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => errorHandler(error));
