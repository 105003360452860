import { api } from './configs/axiosConfigs';

export const GameApi = {
    getGames: async (token) => {
        const response = await api.get('/game', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }
};
