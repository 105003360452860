import { api } from './configs/axiosConfigs';

export const ChoiceApi = {
    createChoice: async (token, gameSessionId, choice) => {
        const response = await api.post(
            `/gamesession/${gameSessionId}/choices`,
            choice,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    },
    undoLastChoice: async (token, gameSessionId) => {
        const response = await api.delete(
            `/gamesession/${gameSessionId}/choices/undo`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    }
};
