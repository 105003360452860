import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import Navbar from 'scenes/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { GameSessionApi } from 'api/gameSessionApi';
import { selectToken, setLogout } from 'features/auth/authSlice';
import { GameApi } from 'api/gameApi';
import { useEffect, useState } from 'react';

const GameSessionConfigPage = () => {
    const currentUserToken = useSelector(selectToken);
    const isNonMobile = useMediaQuery('(min-width: 1000px)');

    // const { palette } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formSchema = yup.object().shape({
        game: yup.string().required('required'),
        name: yup.string().required('required')
    });

    const initialValues = {
        game: '',
        name: ''
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            await GameSessionApi.createGameSession(currentUserToken, values);
            onSubmitProps.resetForm();
            navigate('/');
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
            console.log(error);
        }
    };

    const handleBackToGameSessionsList = () => {
        navigate('/');
    };

    const [gamesList, setGamesList] = useState([]);

    const handleGetGamesList = async () => {
        try {
            const gamesList = await GameApi.getGames(currentUserToken);
            setGamesList(gamesList);
            if (gamesList.length > 0) {
                initialValues.game = gamesList[0]._id;
            }
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
            console.log(error);
        }
    };

    useEffect(() => {
        handleGetGamesList();
    }, []);

    return (
        <Box>
            <Navbar />
            <Box width="100%" padding="2rem 6%" gap="0.5rem" textAlign="center">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    '& > div': {
                                        gridColumn: !isNonMobile && 'span 4'
                                    }
                                }}
                            >
                                <TextField
                                    label="Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    sx={{
                                        gridColumn: 'span 4'
                                    }}
                                />
                                {
                                    // <TextField
                                    //     label="Game"
                                    //     onBlur={handleBlur}
                                    //     onChange={handleChange}
                                    //     value={values.game}
                                    //     name="game"
                                    //     error={touched.game && Boolean(errors.game)}
                                    //     helperText={touched.game && errors.game}
                                    //     sx={{
                                    //         gridColumn: 'span 4'
                                    //     }}
                                    // />
                                }
                                <Select
                                    value={values.game}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="game"
                                    error={touched.game && Boolean(errors.game)}
                                    sx={{
                                        gridColumn: 'span 4'
                                    }}
                                    label="Game"
                                >
                                    {gamesList.map((game) => {
                                        return (
                                            <MenuItem
                                                value={game._id}
                                                key={game._id}
                                            >
                                                {game.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        gridColumn: 'span 4'
                                    }}
                                    size="large"
                                >
                                    Create Game Session
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        gridColumn: 'span 4'
                                    }}
                                    onClick={handleBackToGameSessionsList}
                                    size="large"
                                >
                                    Back to Game Sessions List
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default GameSessionConfigPage;
