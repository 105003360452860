import { api } from './configs/axiosConfigs';

export const GameSessionApi = {
    createGameSession: async (token, gameSession) => {
        const response = await api.post('/gamesession/create', gameSession, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },

    getGameSession: async (token, gameSessionId) => {
        const response = await api.get(`/gamesession/${gameSessionId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },

    getGameSessions: async (token) => {
        const response = await api.get('/gamesession', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },

    updateGameSession: async (token, gameSessionId, gameSession) => {
        const response = await api.put(
            `/gamesession/${gameSessionId}`,
            gameSession,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    },

    deleteGameSession: async (token, gameSessionId) => {
        const response = await api.delete(`/gamesession/${gameSessionId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }
};
