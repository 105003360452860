import { api } from './configs/axiosConfigs';

export const QuestionApi = {
    getNextQuestion: async (token, gameSessionId) => {
        const response = await api.get(
            `/gamesession/${gameSessionId}/question`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    },
    getRemainingCharacters: async (token, gameSessionId) => {
        const response = await api.get(
            `/gamesession/${gameSessionId}/remainingCharacters`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    }
};
