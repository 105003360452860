import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import gameSessionReducer from 'features/gameSessions/gameSessionSlice';
import themeReducer from 'features/theme/themeSlice';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = { key: 'root', version: 1, storage };
// const persistedReducer = persistReducer(persistConfig, authReducer);
const rootReducer = combineReducers({
    auth: authReducer,
    gameSession: gameSessionReducer,
    theme: themeReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export const persistor = persistStore(store);
