import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    token: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogin: (state, action) => {
            console.log('Logging in', action.payload);
            state.user = action.payload.email;
            state.token = action.payload.accessToken;
        },
        setLogout: (state) => {
            console.log('Logging out');
            state.user = null;
            state.token = null;
        }
    }
});

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectIsLoggedIn = (state) => Boolean(state.auth.token);

export const { setLogin, setLogout } = authSlice.actions;
export default authSlice.reducer;
