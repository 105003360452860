import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Navbar from 'scenes/navbar';
import { useDispatch, useSelector } from 'react-redux';
import TinderCard from 'react-tinder-card';
import { useState, useEffect, useRef, useMemo } from 'react';
import React from 'react';
import { Cancel, CheckCircle, History } from '@mui/icons-material';
import { QuestionApi } from 'api/questionApi';
import { selectToken } from 'features/auth/authSlice';
import { GameSessionApi } from 'api/gameSessionApi';
import { ChoiceApi } from 'api/choiceApi';
import { setLogout } from 'features/auth/authSlice';

const GameSessionPage = () => {
    const [db, setDb] = useState([]);
    const [gameSession, setGameSession] = useState();
    const [loaded, setLoaded] = useState(false);

    const { gameSessionId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUserToken = useSelector(selectToken);

    useEffect(() => {
        handleGetRemainingCharacters();
        handleGetGameSession();
    }, []);

    const handleGetRemainingCharacters = async () => {
        try {
            const response = await QuestionApi.getRemainingCharacters(
                currentUserToken,
                gameSessionId
            );
            setDb(response);
            const lastIndex = response.length - 1;
            setCurrentIndex(lastIndex); // Setting the initial value from the fetched db
            currentIndexRef.current = lastIndex;
            setCurrentCharacter(response[lastIndex]);
            setLoaded(true);

            setChildRefs(
                Array(response.length)
                    .fill(0)
                    .map(() => React.createRef())
            );

            console.log(childRefs);
            // console.log('currentCharacter', currentCharacter);
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
        }
    };

    const handleGetGameSession = async () => {
        try {
            const response = await GameSessionApi.getGameSession(
                currentUserToken,
                gameSessionId
            );
            setGameSession(response);
            setSmashes(response.smashes);
            setPasses(response.passes);
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
        }
    };

    const [smashes, setSmashes] = useState(0);
    const [passes, setPasses] = useState(0);

    const handleVote = async (character, option) => {
        try {
            await ChoiceApi.createChoice(currentUserToken, gameSessionId, {
                characterId: character?.id,
                option
            });
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
        }
    };

    const handleUndoVote = async () => {
        try {
            await ChoiceApi.undoLastChoice(currentUserToken, gameSessionId);
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(setLogout());
            }
        }
    };

    const [currentIndex, setCurrentIndex] = useState(db.length - 1);
    const [lastDirection, setLastDirection] = useState();

    const currentIndexRef = useRef(currentIndex);

    const [childRefs, setChildRefs] = useState([]);

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val);
        currentIndexRef.current = val;
    };

    const [canGoBack, setCanGoBack] = useState(false);

    const canSwipe = currentIndex >= 0;

    const [currentCharacter, setCurrentCharacter] = useState(null);

    const swiped = (direction, index) => {
        const swipedCharacter = db[currentIndex];
        const formattedDirection = direction === 'right' ? 'SMASH' : 'PASS';
        handleVote(swipedCharacter, formattedDirection);
        if (formattedDirection === 'SMASH') {
            setSmashes(smashes + 1);
        } else {
            setPasses(passes + 1);
        }
        setLastDirection(direction);
        updateCurrentIndex(index - 1);
        setCanGoBack(true);

        setCurrentCharacter(db[index - 1]);
    };

    const swipe = async (dir) => {
        if (canSwipe && currentIndex < db.length) {
            await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
        }
    };

    const goBack = async () => {
        if (!canGoBack) return;
        setCanGoBack(false);
        if (lastDirection === 'right') {
            setSmashes(smashes - 1);
        } else {
            setPasses(passes - 1);
        }
        handleUndoVote();
        const newIndex = currentIndex + 1;
        setCurrentCharacter(db[newIndex]);
        updateCurrentIndex(newIndex);
        await childRefs[newIndex].current.restoreCard();
    };

    const handleBackToGameSessionsList = () => {
        navigate('/');
    };

    const isNonMobile = useMediaQuery('(min-width: 1000px)');

    return (
        <Box>
            {isNonMobile && <Navbar />}
            <Box
                width="100%"
                height="100vh"
                padding="2rem 6%"
                gap="0.5rem"
                textAlign="center"
            >
                {loaded && (
                    <Box>
                        <h1>{currentCharacter?.name}</h1>
                        <Divider />
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 2, height: '50vh' }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    fontWeight="bold"
                                    color="error"
                                    fontSize="32px"
                                >
                                    Passes: {passes}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                sx={{ height: '100%' }}
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                            >
                                <div className="cardContainer">
                                    {db.map((character, index) => (
                                        <TinderCard
                                            ref={childRefs[index]}
                                            className="swipe"
                                            key={character?.id}
                                            onSwipe={(dir) =>
                                                swiped(dir, index)
                                            }
                                            preventSwipe={['up', 'down']}
                                        >
                                            <div
                                                style={{
                                                    backgroundImage:
                                                        'url(' +
                                                        character?.image +
                                                        '.png)'
                                                }}
                                                className="card"
                                            >
                                                <h3>{character?.name}</h3>
                                            </div>
                                        </TinderCard>
                                    ))}
                                </div>
                                {/*<img
                            src={`${currentCharacter?.image}.png`}
                            alt={currentCharacter?.name}
                            style={{
                                height: '100%'
                            }}
                        />
                        */}
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography
                                    fontWeight="bold"
                                    color="success"
                                    fontSize="32px"
                                >
                                    Smashes: {smashes}
                                </Typography>
                            </Grid>
                            <Grid item xs={10} order={{ xs: 1, sm: 2 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                >
                                    <Grid item xs={6} sm={3}>
                                        <Button
                                            startIcon={<Cancel />}
                                            fullWidth={true}
                                            variant="contained"
                                            size="large"
                                            color="error"
                                            onClick={() => swipe('left')}
                                        >
                                            PASS
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={3}
                                        order={{ xs: 3, sm: 2 }}
                                    >
                                        <Button
                                            startIcon={<History />}
                                            fullWidth={true}
                                            variant="contained"
                                            size="large"
                                            color="warning"
                                            disabled={!canGoBack}
                                            onClick={goBack}
                                        >
                                            UNDO LAST
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={3}
                                        order={{ xs: 2, sm: 3 }}
                                    >
                                        <Button
                                            startIcon={<CheckCircle />}
                                            fullWidth={true}
                                            variant="contained"
                                            size="large"
                                            color="success"
                                            onClick={() => swipe('right')}
                                        >
                                            SMASH
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} order={{ xs: 4 }}>
                                        <Button
                                            variant="outlined"
                                            onClick={
                                                handleBackToGameSessionsList
                                            }
                                            size="large"
                                        >
                                            Back to Game Sessions List
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default GameSessionPage;
