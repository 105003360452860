import { useState } from 'react';
import {
    Box,
    IconButton,
    InputBase,
    Typography,
    Select,
    MenuItem,
    FormControl,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Search,
    Message,
    DarkMode,
    LightMode,
    Notifications,
    Help,
    Menu,
    Close
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from 'features/auth/authSlice';
import { setMode } from 'features/theme/themeSlice';
import { useNavigate } from 'react-router-dom';
import FlexBetween from 'components/FlexBetween';
// import { fontSize } from '@mui/system';

const Navbar = () => {
    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const isNonMobile = useMediaQuery('(min-width: 1000px)');

    const theme = useTheme();
    const neutralLight = theme.palette.secondary.light;
    const neutralDark = theme.palette.secondary.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;
    const userName = user;

    return (
        <FlexBetween className="Base" padding="1rem 6%" backgroundColor={alt}>
            <FlexBetween gap="1.75rem">
                <Typography
                    fontWeight="bold"
                    fontSize="clamp(1rem, 2rem, 2.25rem)"
                    color="primary"
                    onClick={() => navigate('/')}
                    sx={{
                        '&:hover': { color: primaryLight, cursor: 'pointer' }
                    }}
                >
                    ShouldISmash
                </Typography>
                {/*
                {isNonMobile && (
                    <FlexBetween
                        backgroundColor={neutralLight}
                        borderRadius="9px"
                        gap="3rem"
                        padding="0.1rem 1.5rem"
                    >
                        <InputBase
                            placeholder="Search..."
                            sx={{
                                '&::placeholder': {
                                    color: neutralDark
                                }
                            }}
                        />
                        <IconButton>
                            <Search />
                        </IconButton>
                    </FlexBetween>
                )}
                */}
            </FlexBetween>
            {/* Desktop Nav */}
            {isNonMobile ? (
                <FlexBetween gap="2rem">
                    <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === 'dark' ? (
                            <LightMode
                                sx={{
                                    color: neutralDark,
                                    fontSize: '25px'
                                }}
                            />
                        ) : (
                            <DarkMode sx={{ fontSize: '25px' }} />
                        )}
                    </IconButton>
                    {/* <Message sx={{ fontSize: '25px' }} /> */}
                    {/* <Notifications sx={{ fontSize: '25px' }} /> */}
                    <Help sx={{ fontSize: '25px' }} />
                    <FormControl variant="standard" value={userName}>
                        <Select
                            value={userName}
                            sx={{
                                backgroundColor: neutralLight,
                                width: '300px',
                                borderRadius: '0.25rem',
                                p: '0.25rem 1rem',
                                '& .MuiSvgIcon-root': {
                                    pr: '0.25rem',
                                    width: '3rem'
                                },
                                '& .MuiSelect-select:focus': {
                                    backgroundColor: neutralLight
                                }
                            }}
                            input={<InputBase />}
                        >
                            <MenuItem value={userName}>
                                <Typography>{userName}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => dispatch(setLogout())}>
                                <Typography>Logout</Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </FlexBetween>
            ) : (
                <IconButton
                    onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                >
                    <Menu />
                </IconButton>
            )}
            {/* Mobile Nav */}
            {!isNonMobile && isMobileMenuToggled && (
                <Box
                    position="fixed"
                    right="0"
                    bottom="0"
                    height="100%"
                    zIndex="10"
                    maxWidth="500px"
                    minWidth="400px"
                    backgroundColor={background}
                >
                    <Box display="flex" justifyContent="flex-end" p="1rem">
                        <IconButton
                            onClick={() =>
                                setIsMobileMenuToggled(!isMobileMenuToggled)
                            }
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    <FlexBetween
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="3rem"
                    >
                        <IconButton onClick={() => dispatch(setMode())}>
                            {theme.palette.mode === 'dark' ? (
                                <LightMode
                                    sx={{
                                        color: neutralDark,
                                        fontSize: '25px'
                                    }}
                                />
                            ) : (
                                <DarkMode sx={{ fontSize: '25px' }} />
                            )}
                        </IconButton>
                        {/* <Message sx={{ fontSize: '25px' }} /> */}
                        {/* <Notifications sx={{ fontSize: '25px' }} /> */}
                        <Help sx={{ fontSize: '25px' }} />
                        <FormControl variant="standard" value={userName}>
                            <Select
                                value={userName}
                                sx={{
                                    backgroundColor: neutralLight,
                                    width: '300px',
                                    borderRadius: '0.25rem',
                                    p: '0.25rem 1rem',
                                    '& .MuiSvgIcon-root': {
                                        pr: '0.25rem',
                                        width: '3rem'
                                    },
                                    '& .MuiSelect-select:focus': {
                                        backgroundColor: neutralLight
                                    }
                                }}
                                input={<InputBase />}
                            >
                                <MenuItem value={userName}>
                                    <Typography>{userName}</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(setLogout())}>
                                    <Typography>Logout</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </FlexBetween>
                </Box>
            )}
        </FlexBetween>
    );
};

export default Navbar;
