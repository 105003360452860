import { Google } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';

// import { firebaseAuth } from 'config/firebase-config';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
// import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLogin } from 'features/auth/authSlice';

const LoginPage = () => {
    const dispatch = useDispatch();

    const loginWithGoogle = () => {
        const auth = getAuth();
        signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {
            dispatch(setLogin(result.user));
        });
    };

    const theme = useTheme();

    return (
        <Box>
            <Box
                width="100%"
                backgroundColor={theme.palette.background.alt}
                p="1rem 6%"
                textAlign="center"
            >
                <Typography fontWeight="bold" fontSize="32px" color="primary">
                    ShouldISmash
                </Typography>
            </Box>
            <Box width="100%" padding="2rem 6%" gap="0.5rem" textAlign="center">
                <Button
                    onClick={loginWithGoogle}
                    variant="outlined"
                    startIcon={<Google />}
                >
                    Login with Google
                </Button>
            </Box>
        </Box>
    );
};

export default LoginPage;
