import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 'light'
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setMode: (state) => {
            console.log('Changing mode', state.mode);
            state.mode = state.mode === 'light' ? 'dark' : 'light';
        }
    }
});

export const { setMode } = themeSlice.actions;
export default themeSlice.reducer;
