import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import HomePage from 'scenes/homePage';
import LoginPage from 'scenes/loginPage';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';

import { selectIsLoggedIn } from 'features/auth/authSlice';

// import { store } from 'app/store';

import ProtectedRoute from 'components/ProtectedRoute';
import GameSessionConfigPage from 'scenes/gameSessionConfigPage';
import GameSessionPage from 'scenes/gameSessionPage';

function App() {
    const isAuth = useSelector(selectIsLoggedIn);

    const mode = useSelector((state) => state.theme.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return (
        <div className="app">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route
                            exact
                            path="/login"
                            element={
                                <ProtectedRoute
                                    verification={!isAuth}
                                    redirectPath="/"
                                    children={<LoginPage />}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute
                                    verification={isAuth}
                                    redirectPath="/login"
                                    children={<HomePage />}
                                />
                            }
                        />
                        <Route
                            path="/createGameSession"
                            element={
                                <ProtectedRoute
                                    verification={isAuth}
                                    redirectPath="/login"
                                    children={<GameSessionConfigPage />}
                                />
                            }
                        />
                        <Route
                            path="/gameSession/:gameSessionId"
                            element={
                                <ProtectedRoute
                                    verification={isAuth}
                                    redirectPath="/login"
                                    children={<GameSessionPage />}
                                />
                            }
                        />
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
